<template>
  <div class="list row pt-5">
    <div class="col-6 col-md-6">
      <div class="card"> 
        <div class="card-header">
      <h4>Companies List</h4>
        </div>
        <div class="card-body">
      <ul class="list-group">
        <li
          class="list-group-item"
          :class="{ active: index == currentIndex }"
          v-for="(companyRecord, index) in companyRecords"
          :key="index"
          @click="setActiveCompanyRecord(companyRecord, index)"
        >
          {{ companyRecord.title }}
        </li>
      </ul>
        </div>
      <button class="m-3 btn btn-sm btn-danger" @click="removeAllCompanyRecords">
        Remove All
      </button>
      </div>
    </div>
    <div class="col- 6 col-md-6">
      <div class="card">
      <div v-if="currentCompanyRecord" class="card-body">
        <companyRecord-details
          :companyRecord="currentCompanyRecord"
          @refreshList="refreshList"
        />
      </div>
      <div v-else>
        <br />
        <p>Click on a company to view record...</p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import FirebaseDataService from "@/services/FirebaseDataService";
import companyRecordDetails from "./CompanyRecord";

export default {
  name: "companyRecords-list",
  components: { companyRecordDetails },
  data() {
    return {
      companyRecords: [],
      currentCompanyRecord: null,
      currentIndex: -1
    };
  },
  methods: {
    onDataChange(items) {
      let _companyRecords = [];

      items.forEach((item) => {
        let key = item.key;
        let data = item.val();
        _companyRecords.push({
          key: key,
          title: data.title,
          description: data.description,
          published: data.published,
        });
      });

      this.companyRecords = _companyRecords;
    },

    refreshList() {
      this.currentCompanyRecord = null;
      this.currentIndex = -1;
    },

    setActiveCompanyRecord(companyRecord, index) {
      this.currentCompanyRecord = companyRecord;
      this.currentIndex = index;
    },

    removeAllCompanyRecords() {
      FirebaseDataService.deleteAll()
        .then(() => {
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    FirebaseDataService.getAll().on("value", this.onDataChange);
  },
  beforeUnmount() {
    FirebaseDataService.getAll().off("value", this.onDataChange);
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0px;
  max-height: 60vh;
  overflow-y: auto;
  ul{
    border-radius: 1rem;
  }
  li{
    background-color: transparent;

    &.active{
      background-color: #6ac7be;
      border:turquoise;
      font-weight: 800;
    }
  }
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
