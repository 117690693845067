<template>
  <div v-if="currentCompanyRecord" class="edit-form">
            <div class="card-header">
    <h4>Company Data</h4>
            </div>
    <form>
      <div class="form-group">
        <label for="title">Title</label>
        <input
          type="text"
          class="form-control"
          id="title"
          v-model="currentCompanyRecord.title"
        />
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <textarea
          type="text"
          class="form-control"
          id="description"
          rows="3"
          v-model="currentCompanyRecord.description"
        />
      </div>

      <div class="form-group">
        <label><strong>Status:</strong></label>
        {{ currentCompanyRecord.published ? "Published" : "Pending" }}
      </div>
    </form>

    <button
      class="badge badge-primary mr-2"
      v-if="currentCompanyRecord.published"
      @click="updatePublished(false)"
    >
      UnPublish
    </button>
    <button
      v-else
      class="badge badge-primary mr-2"
      @click="updatePublished(true)"
    >
      Publish
    </button>

    <button class="badge badge-danger mr-2" @click="deleteCompanyRecord">
      Delete
    </button>

    <button type="submit" class="badge badge-success" @click="updatecompanyRecord">
      Update
    </button>
    <p>{{ message }}</p>
  </div>

  <div v-else>
    <br />
    <p>Click on a company to view record...</p>
  </div>
</template>

<script>
import FirebaseDataService from "@/services/FirebaseDataService";

export default {
  name: "companyRecord",
  props: ["companyRecord"],
  data() {
    return {
      currentCompanyRecord: null,
      message: "",
    };
  },
  watch: {
    companyRecord: function(companyRecord) {
      this.currentCompanyRecord = { ...companyRecord };
      this.message = "";
    },
  },
  methods: {
    updatePublished(status) {
      FirebaseDataService.update(this.currentCompanyRecord.key, {
        published: status,
      })
        .then(() => {
          this.currentCompanyRecord.published = status;
          this.message = "The status was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updatecompanyRecord() {
      const data = {
        title: this.currentCompanyRecord.title,
        description: this.currentCompanyRecord.description,
      };

      FirebaseDataService.update(this.currentCompanyRecord.key, data)
        .then(() => {
          this.message = "The company record was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteCompanyRecord() {
      FirebaseDataService.delete(this.currentCompanyRecord.key)
        .then(() => {
          this.$emit("refreshList");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.message = "";
    this.currentCompanyRecord = { ...this.companyRecord }
  },
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
